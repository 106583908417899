@charset "UTF-8";
/* 
Адаптация 
*/
@media screen and (max-width: 1289px) {
  .competitions_wrap {
    width: 100%; } }

@media screen and (max-width: 1229px) {
  .container {
    width: 992px; }
  .files_item {
    width: 50%; }
  .files_wrap {
    margin-top: -10px; }
  .competitions_title {
    margin-top: -33px; }
  .partners_box {
    width: 166px;
    flex-direction: column;
    margin-top: 55px; }
  .parnners_logo {
    margin-right: 0;
    margin-bottom: 18px; }
  table td:nth-child(5) {
    width: 175px; }
  table tr {
    height: 190px; }
  .contacts_wrap {
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    width: 600px;
    margin-top: 20px; }
  .registration_form {
    width: 960px; } }

@media screen and (max-width: 1110px) {
  .factory_bottom_box {
    padding: 85px 0 15px 0;
    margin-top: 85px; }
  .factory_bottom_box_title1:before {
    left: 0;
    top: -60px;
    right: 0;
    margin: auto; }
  .factory_bottom_box_title2:before {
    left: 0;
    top: -68px;
    right: 0;
    margin: auto; } }

@media screen and (max-width: 991px) {
  .container {
    width: 768px; }
  .registration_form {
    flex-direction: column; }
  .registration_form {
    width: 100%; }
    .registration_form input, .registration_form div, .registration_form select {
      width: 100%; }
    .registration_form button {
      margin-top: 40px;
      width: 100%; }
  .registration_box {
    width: 100%; }
    .registration_box img {
      width: 260px; }
  table tr:nth-child(1) {
    font-size: 12px; }
  table td:nth-child(5) {
    width: 150px; }
  table tr {
    height: 160px; }
  .factory_tasks_box {
    width: 100%;
    flex-direction: column;
    text-align: center;
    margin-bottom: 70px; }
    .factory_tasks_box_revers {
      flex-direction: column-reverse; }
  .factory_tasks_text_box {
    margin-top: -25px; }
  .factory_task_text {
    width: 100%; }
  .menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 26, 85, 0.9);
    width: 100%;
    height: 100vh;
    padding-top: 33px; }
  .menu ul {
    display: block; }
  .menu ul li {
    display: block; }
  .menu ul li a {
    display: block;
    line-height: 40px;
    text-align: center; }
  #sandwich {
    top: -85px;
    right: 0;
    display: block; }
  .container_sandwich {
    position: relative; }
  .factory_title {
    width: 470px;
    height: 109px;
    padding: 17px 69px 0 0;
    background-size: 470px; }
  .partners_wrap {
    flex-wrap: wrap;
    justify-content: space-around; }
  #partners {
    background: url(../img/bg/bg-registered.png) no-repeat top center;
    height: 860px; }
  #registration {
    background: url(../img/bg/bg-registered.png) no-repeat top center;
    padding-top: 10px; }
  #files {
    background: url(../img/bg/bg-registered.png) no-repeat top center;
    padding-top: 10px;
    height: 660px; }
  #competitions {
    background: url(../img/bg/bg-registered.png) no-repeat top center;
    padding-top: 130px; }
  .registration_box {
    margin-top: 125px; }
  .partners_wrap {
    margin-top: 0; }
  .competitions_icon {
    display: none; }
  .competitions_item {
    background: none; }
  .competitions_wrap {
    width: 95%; }
  .competitions_item {
    padding-left: 0; }
  .factory_bottom_box {
    width: 100%; }
  #footer {
    padding: 0 0; }
  .map_title {
    padding: 51px 0 0 0; }
  .ymaps-2-1-73-image {
    width: 300px !important;
    height: 91px !important;
    background-size: 300px !important; } }

@media screen and (max-width: 767px) {
  .container {
    width: 576px; }
  .factory_bottom_box_text {
    width: 95%;
    text-align: center; }
  .files_item {
    width: 100%; }
  #files {
    height: auto; }
  .competitions_title {
    margin-bottom: 42px; }
  .factory_title {
    background-size: 374px; }
  .registration_title {
    padding: 27px 0px 0 0;
    background-size: 438px; }
  .registration_box img {
    display: none; }
  .registration_form {
    margin-top: 20px; }
  .registered_box {
    flex-wrap: wrap; }
  .registered_box select {
    margin-top: -18px;
    margin-bottom: 35px; }
  table tr {
    font-size: 14px; }
  table tr:nth-child(2) {
    font-size: 14px; }
  table tr:nth-child(1) {
    font-size: 9px; }
  .contacts_phone {
    font-size: 23px; }
  .contacts_icon {
    width: 60px; }
  .contacts_wrap {
    width: 458px; }
  .map_title {
    padding: 23px 0 0 0;
    background: #0066b5; } }

@media screen and (max-width: 575px) {
  .container {
    width: 480px; }
  .one_title {
    font-size: 80px; }
  .one_subtitle {
    font-size: 60px;
    margin-top: -70px; }
  .one_date {
    margin-top: -50px; }
  .one_city {
    margin-top: 50px; }
  .factory_title_box {
    flex-wrap: wrap; }
  .factory_title {
    background-size: 450px; }
  .factory_btn {
    margin-top: -30px;
    margin-bottom: 40px; }
  .factory_bottom_box_title2 {
    margin-left: 0; }
  table tr {
    font-size: 12px; }
  table tr:nth-child(2) {
    font-size: 12px; }
  table tr:nth-child(1) {
    font-size: 9px; }
  #competitions {
    padding-bottom: 100px; }
  #partners {
    height: 1125px;
    margin-top: 50px; }
  .factory_bottom_btn {
    width: 100%; } }

@media screen and (max-width: 479px) {
  .container {
    width: 380px; }
  .menu ul li a {
    text-align: left;
    padding-left: 15px; }
  .one_title {
    font-size: 65px; }
  .one_subtitle {
    font-size: 45px;
    margin-top: -53px; }
  .one_date {
    margin-top: -33px; }
  .factory_title {
    background-size: 379px;
    padding: 23px 69px 0 0; }
  .factory_task_title {
    font-size: 23px;
    line-height: 33px; }
  .factory_task_text {
    font-size: 20px;
    margin-top: -22px; }
  .contacts_wrap .contacts_item {
    margin-top: -22px; }
  .contacts_phone {
    font-size: 21px; }
  .contacts_wrap {
    width: 350px;
    justify-content: center; }
  .contacts_separator {
    display: none; }
  .contacts_wrap .contacts_item {
    flex-wrap: wrap; }
  .contacts_phone {
    font-size: 24px; }
  .contacts_wrap .contacts_item {
    margin-top: 0; }
  .contacts_icon {
    display: none; }
  table tr {
    font-size: 9px; }
  table tr:nth-child(2) {
    font-size: 9px; }
  table tr:nth-child(1) {
    font-size: 7px; }
  h2 {
    font-size: 38px; }
  .files_title {
    width: 284px;
    padding: 28px 45px 0 0;
    background-size: 260px; }
  .parners_title {
    width: 326px;
    padding: 30px 45px 0 0;
    background-size: 314px; }
  .ymaps-2-1-73-image {
    width: 200px !important;
    height: 61px !important;
    background-size: 200px !important; }
  .registration_title {
    padding: 27px 61px 0 0;
    background-size: 300px;
    width: 300px; }
  .registration_form img {
    width: 100%; }
  .contacts_title {
    width: 310px;
    height: 109px;
    padding: 30px 45px 0 0;
    background-size: 305px; }
  .map_title {
    height: 140px; } }

@media screen and (max-width: 379px) {
  .container {
    width: 320px; }
  .one_title {
    font-size: 50px; }
  .one_subtitle {
    font-size: 36px;
    margin-top: -38px; }
  .one_date {
    margin-top: -22px;
    font-size: 30px; }
  .one_city {
    font-size: 30px; }
  #one {
    padding-top: 150px; }
  h2 {
    font-size: 30px; }
  .factory_title {
    background-size: 305px;
    padding: 19px 69px 0 0;
    height: 90px; }
  .factory_bottom_btn {
    font-size: 25px; }
  .files_title {
    padding: 33px 45px 0 0; }
  .parners_title {
    width: 290px;
    padding: 35px 45px 0 0;
    background-size: 275px; }
  .partners_wrap {
    margin-top: -45px; }
  #partners {
    height: 1055px;
    margin-top: 62px; }
  .registration_title {
    padding: 34px 61px 0 0;
    background-size: 285px;
    width: 290px; }
  .registration_form {
    margin-top: 9px; }
  .contacts_title {
    width: 290px;
    height: 109px;
    padding: 34px 45px 0 0;
    background-size: 260px; }
  .contacts_wrap {
    width: 290px; }
  table tr {
    font-size: 7px; }
  table tr:nth-child(2) {
    font-size: 7px; }
  table tr:nth-child(1) {
    font-size: 5px; }
  .factory_tasks_box {
    margin-bottom: 25px; }
  #competitions {
    padding-bottom: 200px; }
  .registration_form select {
    font-size: 16px; }
  .map_title {
    height: 123px; }
  .copyright {
    font-size: 20px; } }
